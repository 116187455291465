.wrapper {
  display: flex;
  align-items: center;

  :global {
    .ant-checkbox {
      top: 0;
    }

    .ant-checkbox-checked::after {
      border-radius: var(--border-radius-4);
      border-color: var(--color-active-text-hover);
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--color-active-icon);
      border-color: var(--color-active-icon);
    }

    .ant-checkbox-inner {
      padding: var(--spacing-4);
      border-radius: var(--border-radius-4);
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
      border-color: var(--color-active-text-hover);
    }
  }
}
