
.checkedStatus {
  padding: var(--spacing-8) !important;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.link {
  cursor: pointer;

  span {
    color: var(--color-active-text);
  }

  &:hover {
    span {
      color: var(--color-active-text-hover);
    }
  }
}

.number {
  width: 32px;
}
