.wrapper {
  display: flex;
  gap: 4px;
}

.eye {
  color: var(--color-icon-purple);
}

.clipboardNotes {
  color: var(--color-icon-green);
}
