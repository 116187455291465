.wrapper {
  column-fill: balance;
  column-count: 3;
  column-gap: var(--spacing-24);
  padding: var(--spacing-24);
  background: var(--modal-background);
  border-radius: var(--border-radius-default);
  margin-bottom: var(--spacing-12);
}

.option {
  margin-bottom: 16px;
}

.optionSelected {
  color: var(--color-active-text);
}

.optionsGroupWrapperLabel {
  color: var(--color-disabled-text)
}

.optionsGroupWrapper {
  display: flex;
}


.optionsWrapper {
  margin-top: -2px;
  margin-left: var(--spacing-16);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
