.input {
  background: transparent !important;
  border-radius: var(--border-radius-default);
  color: var(--color-default);
  height: var(--input-height);
  padding: var(--spacing-8) var(--spacing-12);
  outline: none;
  box-sizing: border-box;
  border-color: var(--color-active-icon);

  :global {
    .ant-input {
      background: transparent;
    }
  }

  svg {
    color: var(--color-active-icon);
  }
}

.small {
  height: var(--input-height-small);
}

.medium {
  height: var(--input-height);
}

.large {
  height: var(--input-height-large);
}

.fullWidth {
  width: 100%;
}



