.wrapper {
  :global {

    .ant-modal-content {
      .ant-modal-body {
        padding: var(--spacing-24) !important;
      }
    }
  }
}

.modalActions {
  width: 100%;
  display: flex;
  gap: var(--spacing-12);
  margin-top: 24px;

  > :first-child {
    background: var(--color-simple-lines);
    border-color: var(--color-simple-lines);
    color: var(--color-text-default);

    &:hover {
      background: var(--color-darker-lines);
      border-color: var(--color-darker-lines);
    }
  }
}

.modalLabel {
  margin-bottom: 8px;
}

.approveLabel {
  color: var(--color-simple-green);
}

.declineLabel {
  color: var(--color-simple-red);
}

.approveButton {
  > :last-child {
    background: var(--color-simple-green) !important;
    border-color: var(--color-simple-green) !important;

    &:hover {
      background: var(--color-simple-green-hovered) !important;
    }
  }
}

.declineButton {
  > :last-child {
    background: var(--color-simple-red) !important;
    border-color: var(--color-simple-red) !important;

    &:hover {
      background: var(--color-simple-red-hovered) !important;
    }
  }
}
