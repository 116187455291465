$sidebar-trigger-height: 80px;
$copy-filters-button-height: 48px;

.collapsed {
  visibility: collapse;
  opacity: 0;
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.filtersWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  overflow-y: auto;
  height: calc(100vh - var(--header-height) - $sidebar-trigger-height - $copy-filters-button-height);
  padding: 0 var(--spacing-24);
  color: var(--color-text-default);

  > :not(:last-child) {
    padding-bottom: var(--spacing-16);
    border-bottom: 1px solid var(--sidebar-delimiter-color);
  }
}

.skeletonLabelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-disabled-text);
  margin-bottom: var(--spacing-16);
}

.skeletonElementWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-12);
  margin-bottom: var(--spacing-16);

  color: var(--color-disabled-text);
}
