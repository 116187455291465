.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-active-text);
  cursor: pointer;
}
.wrapper:hover {
  color: var(--color-active-text-hover);
}
