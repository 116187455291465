.wrapper {
  font-family: var(--font-family--sfmono);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-600);
  color: var(--color-text-default);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-16);
  padding: var(--spacing-24) 0 var(--spacing-24) var(--spacing-32);
  background: var(--section-background);
}

.goBackLink {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--color-default);
}
.goBackLink:hover {
  color: var(--color-active-text-hover);
}
