.transformHash {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-4);
  text-align: center;
}

.delimiter {
  font-size: 6px
}
