.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 18px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    height: 14px;
    width: 14px;
  }
}

.eye {
  color: var(--color-icon-purple);
}

.clipboardNotes {
  color: var(--color-icon-green);
}
