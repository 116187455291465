.header {
  padding: var(--spacing-16) 0 var(--spacing-24) var(--spacing-32);
}

.breadcrumbsWrapper {
  display: flex;
  align-items: center;
  margin: 0 var(--spacing-32);
  height: 36px;
  border-bottom: 1px solid var(--border-color);
  background: var(--sidebar-background);
}

.actions {
  top: var(--spacing-56);
}
