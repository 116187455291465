.wrapper {
  cursor: pointer;
}

.link {
  cursor: default;

  span {
    color: var(--color-active-text);
  }

  &:hover {
    span {
      color: var(--color-active-text-hover);
    }
  }
}

