.wrapper {
  position: relative;
  height: 32px;
  min-width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  background: var(--tag-background) !important;
  border: none !important;
  border-radius: var(--border-radius-default) !important;
  margin-right: 0 !important;
  box-shadow: 0 2px 6px -20px rgba(0, 0, 0, 0.08), 0px 4px 12px -20px rgba(0, 0, 0, 0.02);

  svg {
    height: 12px;
    width: 12px;
    color: var(--tag-close-icon);
  }

  > :first-child {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global {
    .ant-tag-close-icon {
      position: absolute;
      right: var(--spacing-8);
      top: 9px;

      &:hover {
        color: var(--color-text-main)
      }
    }
  }
}

.wrapper:hover {
  background: var(--content-background-hover) !important;
}

.wrapperWithIcon {

  > :first-child {
    padding-right: var(--spacing-16);
  }
}
