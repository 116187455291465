.wrapper {
  display: flex;
  justify-content: right;
  align-items: center;
  flex-wrap: wrap;
}

.copier {
  justify-content: right;
  width: 100%;
}
