.wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.highlighted {
  color: var(--color-critical-amount);
}
