.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: var(--font-size-13);
}

.wrapper:hover {
  color: var(--color-active-text-hover)
}

.isSelected {
  font-weight: var(--font-weight-600);
  color: var(--color-active-text)
}
