$breadcrumbs-height: 32px;

.header {
  padding: var(--spacing-16) 0 var(--spacing-24) var(--spacing-32);
}

.breadcrumbsWrapper {
  display: flex;
  align-items: center;
  height: 36px;
  margin: 0 var(--spacing-32);
  border-bottom: 1px solid var(--border-color);
}

.copyButton {
  cursor: pointer;
  color: var(--color-active-text);
  width: 100%;
  border: none;
  background: transparent;
}

.copyButton > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
}

.copyButton:hover {
  color: var(--color-active-text-hover);
}

.copyButton:active {
  color: var(--color-active-text-active);
}

.actions {
  top: calc(var(--spacing-16) + $breadcrumbs-height);
}

.walletInfo {
  padding-left: var(--spacing-32);
  padding-bottom: var(--spacing-16);
  display: flex;
  align-items: center;
  gap: var(--spacing-8);

  >:nth-child(2) {
    text-transform: uppercase;
  }

  img {
    height: 14px;
  }
}
