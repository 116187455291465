.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: var(--main-bg);
}

.wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-64);
  border-radius: var(--border-radius-default);
  background: var(--layout-background);

  header {
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-20);
    text-align: center;
    color: var(--color-text-default);
    margin-bottom: var(--spacing-40);
  }
}

.logo {
  width: 48px;
  height: 48px;
  margin: 0 auto var(--spacing-24);
}

.formWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.inputWrapper {
  margin-bottom: var(--spacing-40);

  input::placeholder {
    text-align: center;
  }

  :global {
    .ant-input-affix-wrapper-status-error {
      color: var(--color-error);


      svg {
        color: var(--color-error);
      }
    }

    .ant-form-item-explain {
      color: var(--color-error);
      font-size: var(--font-size-13);
      letter-spacing: -0.04em;
      margin: var(--spacing-8) auto var(--spacing-12) auto;
      text-align: center;
    }
  }
}

.buttonWrapper {
  display: flex;
  width: 100%;
  justify-content: center;

  :global {
    .ant-form-item-row {
      width: 100%;
    }
  }
}

.welcome {
  margin-bottom: 24px;
}

.confirmationWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-default);

  h3 {
    display: flex;
    align-items: center;
    color: var(--color-default);

    font-weight: 700;
    font-size: 18px;
    margin-bottom: 16px;
  }
}

.editEmailButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  gap: 4px;

  &:hover {
    color: var(--color-active-icon-hover);
  }

}

.checkInbox {
  width: 280px;
  margin-top: var(--spacing-24);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4px;

  > :nth-child(2) {
    color: var(--color-active-text)
  }

  div {
    display: flex;
    justify-content: center;
    font-size: var(--font-size-15);
  }
}
