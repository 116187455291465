.defaults {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--skeleton-color);
  height: fit-content;

  :global {
    .ant-skeleton-button {
      min-width: 0 !important;
    }
  }
}
