
.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
}

.iconOutcome {
  color: var(--color-error);
}

.iconIncome {
  color: var(--color-success);
}

.iconInside {
  color: var(--color-active-icon);
}
