.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;

  span {
    //font-weight: $font-weight-bold;
  }

  button {
    //background: $color-quartz;
    height: 24px;
    width: 40px;
    display: flex;
    align-items: center;
  }

  button > div:before {
    height: 20px;
    width: 20px;
    //background-color: $color-medium-slate-blue;
    border-radius: 50%;
  }
}

.switcherChecked {
  div:before {
    left: calc(100% - 20px);
  }
}

.isChecked {
  //color: $color-medium-slate-blue;
}
