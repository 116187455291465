.address {
  cursor: default !important;
}

.number {
  width: 32px;
}

.tableWrapper > div {
  max-height: 68vh;
  overflow-y: auto;

  tbody > tr {
    > td:nth-child(2) {
      width: 32px;
      padding: 0 var(--spacing-4);
    }
    > td:nth-child(3) {
      width: 32px;
      padding: 0 var(--spacing-8);
    }
    > td:first-child {
      width: fit-content;
      padding: var(--spacing-12) var(--spacing-4);
    }
  }
}

.checkedStatus {
  padding:  var(--spacing-8) !important;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
