$breadcrumbs-height: 32px;

.body {
  padding: var(--spacing-16) var(--spacing-32) var(--spacing-24);;
}

.header {
  padding: var(--spacing-16) 0 var(--spacing-16) var(--spacing-32);

  > div {

    display: flex;
    flex-direction: row;
    gap: var(--spacing-16);
  }
}

.breadcrumbsWrapper {
  display: flex;
  align-items: center;
  margin: 0 var(--spacing-32);
  height: 36px;
  border-bottom: 1px solid var(--border-color);
  background: var(--sidebar-background);
}

.actions {
  top: calc(var(--spacing-16) + $breadcrumbs-height);
}

.invoiceInfo {
  padding-left: var(--spacing-32);
  padding-bottom: var(--spacing-16);
  display: flex;
  align-items: center;

  >:first-child {
    margin-right: var(--spacing-8);
  }

  >:nth-child(2) {
    text-transform: uppercase;
  }

  img {
    height: 14px;
  }
}

.copyButton {
  cursor: pointer;
  color: var(--color-active-text);
  width: fit-content;
  border: none;
  background: transparent;

  span {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
  }
}
