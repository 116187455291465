.wrapper {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  :global {
    .ant-layout-header {
      height: 66px;
    }

    .ant-layout-sider-has-trigger {
      padding-bottom: 0;
    }
  }
}

.contentWrapper {
  display: flex;
  height: calc(100vh - var(--header-height));
  background: var(--content-background);
}
