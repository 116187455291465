.tableWrapper > div {
  max-height: 59vh;
  overflow-y: auto;

  tbody > tr > td:nth-child(2) {
    width: 32px;
    padding: 0 var(--spacing-4);
  }

  tbody > tr > td:first-child {
    width: fit-content;
    padding: var(--spacing-12) var(--spacing-4);
  }
}

.checkedStatus {
  padding:  var(--spacing-8) !important;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
