.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: var(--button-height);
  border-radius: var(--border-radius-default);

  &.filled {
    background: var(--color-button-filled) !important;
    color: var(--color-button-filled-text);
    border: 1px solid var(--color-button-filled);

    &:hover {
      background: var(--color-button-filled-hovered) !important;
      border-color: var(--color-button-filled-hovered);
      color: var(--color-button-filled-text);
    }

    &:enabled:active {
      background: var(--color-button-filled-active) !important;
      border-color: var(--color-button-filled-active);
      color: var(--color-button-filled-text);
    }

    &:disabled {
      background-color: var(--color-disabled-icon) !important;
      border-color: var(--color-disabled-icon) !important;
      cursor: default;
      color: var(--color-button-filled-text);

      &.loading {
        background-color: var(--color-button-filled-text) !important;
        color: var(--color-button-filled-text);
      }
    }
  }

  &.transparent {
    background: transparent !important;
    color: var(--color-button-filled) !important;
    border: 1px solid transparent !important;
    box-shadow: none;

    &:hover {
      color: var(--color-button-filled-hovered);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:enabled:active {
      color: var(--color-button-filled-active);
    }

    &:disabled {
      box-shadow: none;
      cursor: default;
      color: var(--color-light-disabled-text) !important;

      &.loading {
        background-color: var(--color-button-filled-text) !important;
        color: var(--color-button-filled-text);
      }
    }
  }
}

.small {
  height: var(--button-height-small);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-13);
  line-height: var(--line-height-20);
}

.medium {
  height: var(--button-height);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-13);
  line-height: var(--line-height-20);
}

.large {
  height: var(--button-height-large);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-15);
  line-height: var(--line-height-24);
}

.outlined {
  background: transparent;
}

.fullwidth {
  width: 100%;
}
