.viewAllButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  font-size: var(--font-size-13);

  svg {
    color: var(--color-disabled-text);
  }

  &:hover {
    div {
      color: var(--color-active-text);
    }

    svg {
      color: var(--color-active-icon);
    }
  }
}

.overlay {
  box-shadow: var(--default-box-shadow);
  overflow: auto;
  max-height: 500px;
  width: fit-content;
}

.visible {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12)
}

.hidden {
  height: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
}
