.option {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 14px;
  }
}


.eye {
  color: var(--color-icon-purple);
}

.clipboardNotes {
  color: var(--color-icon-green);
}
