.defaultRow {
  > :first-child {
    background: var(--content-background);
  }

  background: var(--layout-background);
}

.checkedRow {
  td {
    background: var(--color-checked-row) !important;
  }
}

.status-0 {
  td {
    background: var(--layout-background) !important;
  }
}

.status-1 {
  td {
    background: var(--color-viewed-row) !important;
  }
}


.status-2 {
  td {
    background: var(--color-reported-row) !important;
  }
}
