.dropdownButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  div {
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
  }
}

.active {
  div {
    color: var(--color-active-text);

    svg {
      color: var(--color-active-icon);
    }
  }
}

.option {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
}
