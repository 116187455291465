.wrapper {
  :global {

    .ant-tabs-nav {
      border-bottom: 1px solid var(--delimiter-color);
    }

    .ant-tabs-tab {
      font-weight: var(--font-weight-600);
      font-size: var(--font-size-15);
      padding: 0 0 var(--spacing-4);
      letter-spacing: -0.04em;
    }

    .ant-tabs-tab-active > div {
      color: var(--color-active-text) !important;
    }
    .ant-tabs-tab-active > div:hover {
      color: var(--color-active-text-hover) !important;
    }

    .ant-tabs-ink-bar {
      background: var(--color-active-text) !important;
    }
  }
}
