.number {
  width: 32px;
}

.tableWrapper > div {
  max-height: 70vh;
  overflow-y: auto;

  table {
    border: none;
    box-shadow: none;
    border-collapse: separate;
    border-spacing: 0 var(--spacing-4);

    tr {
      border-radius: var(--border-radius-default);
      box-shadow: 0 0 0 1px var(--border-darker-color);

      > :first-child {
        border-top-left-radius: var(--border-radius-default);
        border-bottom-left-radius: var(--border-radius-default);
      }

      > :last-child {
        padding: var(--spacing-6) !important;
        border-bottom-right-radius: var(--border-radius-default);
        border-top-right-radius: var(--border-radius-default);
      }
    }

    thead {
      border-radius: var(--border-radius-default);

      > tr {
        > :first-child {
          border-bottom-left-radius: var(--border-radius-default);
        }
      }
    }

    tbody {


      > tr {
        > td:first-child {
          width: fit-content;
          padding: var(--spacing-12) var(--spacing-4);
        }
      }
    }
  }
}

.inactiveStatus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  width: 100%;
  min-width: 135px;
  color: var(--color-light-text);
  padding: var(--spacing-6) var(--spacing-12);
  border-radius: var(--border-radius-default);
}

.declinedStatus {
  background: var(--color-simple-red);
}

.approvedStatus {
  background: var(--color-simple-green);
}

.option {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 16px;
  }
}

.approvedOption {
  gap: var(--spacing-12);
}

.declinedOption {
  gap: var(--spacing-12);
}

.dropdownButton {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  background: var(--color-simple-main);
  border: none;
  cursor: pointer;
  border-radius: var(--border-radius-default);
  padding: var(--spacing-6) var(--spacing-12);

  svg {
    color: var(--color-light-text);
  }

  div {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
    color: var(--color-light-text);
  }
}

.active {
  background: var(--color-simple-main-hovered);

  svg {
    margin-top: var(--spacing-8);
    transform: translateY(-50%) rotate(180deg);
  }
}

.crossIcon {
  color: var(--color-simple-red);
  width: 16px;
}

.checkIcon {
  margin-left: var(--spacing-2);
  margin-right: var(--spacing-6);
  color: var(--color-simple-green);
}


.lightbulbIcon {
  color: var(--color-simple-gray);
}

.status {
  display: flex;
  justify-content: center;
  width: 42px;
}

.statusHeader {
  width: 42px;
}

.link {
  cursor: pointer;

  span {
    color: var(--color-active-text);
  }

  &:hover {
    span {
      color: var(--color-active-text-hover);
    }
  }
}

.green {
  color: var(--color-simple-green);
}

.yellow {
  color: var(--color-simple-warning);
}

.red {
  color: var(--color-simple-red);
}
