.wrapper {
  height: fit-content;
  width: fit-content;
  font-family: var(--font-family--sfmono);
  letter-spacing: -0.04em
}

.default {
  color: var(--color-text-default);
}

.main {
  color: var(--color-active-text);
}

.secondary {
  color: var(--color-light-text);
}

.orange {
  color: var(--color-text-orange);
}

.error {
  color: var(--color-error);
}

.success {
  color: var(--color-success);
}

.fs-10 {
  font-size: var(--font-size-10);
  line-height: var(--line-height-16);
}

.fs-12 {
  font-size: var(--font-size-12);
  line-height: var(--line-height-18);
}

.fs-13 {
  font-size: var(--font-size-13);
  line-height: var(--line-height-20);
}

.fs-15 {
  font-size: var(--font-size-15);
  line-height: var(--line-height-24);
}

.fs-18 {
  font-size: var(--font-size-15);
  line-height: var(--line-height-28);
}

.fs-20 {
  font-size: var(--font-size-20);
  line-height: var(--line-height-28);
}

.fs-28 {
  font-size: var(--font-size-28);
  line-height: var(--line-height-32);
}


.fw-400 {
  font-weight: var(--font-weight-normal);
}

.fw-500 {
  font-weight: var(--font-weight-500);
}

.fw-600 {
  font-weight: var(--font-weight-600);
}
