

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-32);
  overflow: hidden;
  padding-bottom: var(--spacing-8);
}

.actions {
  width: 240px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: var(--spacing-24);
}

.token {
  max-width: 140px;
}
