.wrapper {
  button {
    color: var(--color-success);
    font-weight: var(--font-weight-500);
  }
}

.dropdownButton {
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: none;

  svg {
    color: var(--color-disabled-icon);
  }

  div {
    display: flex;
    gap: var(--spacing-8);
    align-items: center;
    color: var(--color-active-text);
  }
}


.dropdownButtonActive {
  svg {
    margin-top: 6px;
    transform: translateY(-50%) rotate(180deg);
    color: var(--color-active-text-hover);
  }

  div {
    color: var(--color-active-text-hover);
  }
}

.overlay {

  ul {
    width: 100px;

    li {
      span {
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            color: var(--color-active-icon);
          }
        }
      }
    }
  }
}
