
.leftGroup {
  display: flex;
  align-items: center;
  gap: var(--spacing-64);
}

.logo {
  color: var(--color-logo);
}

.leftLinkGroup {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);

  a {
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
  }
}

.nonActiveLink {
  color: var(--color-text-default);
  padding: var(--spacing-14);
  height: 34px;
  font-size: var(--font-size-15);
  border-radius: var(--border-radius-default);
  background: var(--color-non-active-link);

  div {
    color: var(--color-active-icon);
  }

  svg {
    color: var(--color-active-icon);
  }
}

.activeLink {
  height: 34px;
  padding: var(--spacing-14);
  border-radius: var(--border-radius-default);
  background: var(--color-link-background);
  font-size: var(--font-size-15);
  color: var(--color-default);

  > a > svg {
    color: var(--color-default);
  }
}

.logoWithActionButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    svg {
      color: var(--color-button-filled);
    }

    &:hover {
      svg {
        color: var(--color-button-filled-hovered);
      }
    }
  }
}
