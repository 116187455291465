.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
}

.currency {
  position: relative;
  height: 20px;
  width: 20px;
}

.blockchain {
  position: absolute;
  height: 14px;
  width: 14px;
  bottom: 0;
  left: 10px;
  border-radius: 50% !important;
  border: 1px solid var(--border-image-color);
}
