.wrapper {
  width: calc(var(--sidebar-width) - var(--spacing-24) * 2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: 0;
  left: var(--spacing-24);
  padding-bottom: var(--spacing-12);
  position: fixed;
  color: var(--color-active-text);
  background: var(--sidebar-background);

  span {
    gap: var(--spacing-8);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  button {
    border: none;
    background: var(--sidebar-background);
    padding: 0;
    height: 36px;
  }
}

.wrapper:hover {
  color: var(--color-active-text-hover);
}

.wrapper:active {
  color: var(--color-active-text-active);
}
