.wrapper {
  width: 100%;
  display: flex;
  overflow: hidden;
  gap: var(--spacing-12);


  span {
    color: var(--color-text-default);
  }
}
