
.modalWrapper {
  position: relative;
  color: var(--color-text-default);
}

.closeIcon {
  position: absolute;
  top: -16px;
  right: -16px;
  color: var(--color-disabled-icon);
  cursor: pointer;
  background: transparent;
  border: none;
}

.closeIcon:hover {
  color: var(--color-disabled-text);
}

.header {
  padding: 0;
  margin-bottom: var(--spacing-32);
  font-family: var(--font-family--sfmono);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-600);
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-12);
  width: 320px;
}

.singleSettingsWrapper {
  background: var(--content-background);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: var(--spacing-14);

  span {
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-600);
    margin-bottom: var(--spacing-8);
  }
}

.dropdownOverlayWrapper {
  left: -7px;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 310px;
  max-height: 240px;
  overflow-y: auto;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--sidebar-delimiter-color);

  :first-child {
    border-top-right-radius: var(--border-radius-default);
    border-top-left-radius: var(--border-radius-default);
  }

  :not(:last-child) {
    border-bottom: 1px solid var(--sidebar-delimiter-color);
  }

  :last-child {
    border-bottom-left-radius: var(--border-radius-default);
  }

  button {
    border: none;
    cursor: pointer;
    height: 48px;
    background: var(--dropdown-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-14);
  }

  button:hover {
    background: var(--dropdown-element-hover);
  }
}

.dropdownSelectedItem {
  color: var(--color-active-text)
}
