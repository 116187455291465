.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);

  span {
    text-transform: capitalize;
  }

  img {
    height: 20px;
    width: 20px;
  }
}

.viewed {
  img {
    filter: grayscale(1);
    opacity: 30%;
  }
}
