.wrapper {
  font-size: var(--font-size-13);
  min-width: 110px;
  padding: 0;
  border: none;
  background: transparent;
  color: var(--color-active-text);
  cursor: pointer;
}

.wrapper:hover {
  color: var(--color-active-text-hover);
}

.wrapper:disabled {
  cursor: default;
  color: var(--color-disabled-text);
}
