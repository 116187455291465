

.overlay {
  > ul {
    padding: 0;
    border-radius: 6px;
    border: 1px solid var(--delimiter-color);

    > :first-child {
      border-top-left-radius: var(--border-radius-default);
      border-top-right-radius: var(--border-radius-default);
    }

    > :last-child {
      border-bottom-left-radius: var(--border-radius-default);
      border-bottom-right-radius: var(--border-radius-default);
    }

    li {
      height: 48px;

      &:hover {
        background: var(--dropdown-element-hover);
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--delimiter-color);
      }
    }

  }
}
