.wrapper {
  position: relative;
  overflow: hidden;
}

.tableWrapper {
  position: relative;
  display: block;
  justify-content: center;
  width: 100%;
  height: calc(100% - 300px);
  padding: var(--spacing-2);
  overflow-y: auto;
}

.tableDefaultStyles {
  overflow: auto;
  font-size: var(--font-size-12);
  border-radius: var(--border-radius-default);
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 0 0.5px var(--border-darker-color);
  border: none;

  :global {
    .ant-checkbox-inner {
      background: transparent;
      border-color: var(--color-filter-checkbox);
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--color-active-icon);
      border-color: var(--color-active-icon);
    }
  }

  thead {
    border-spacing: 0;
    background: transparent;
    border: none;
    border-bottom: 0.5px solid var(--border-darker-color);
    border-top-right-radius: var(--border-radius-default);
    border-top-left-radius: var(--border-radius-default);

    tr {
      z-index: calc(var(--z-index-table-overflow) + 1);
      top: -2px;
      position: sticky;
      border-top-right-radius: var(--border-radius-default);
      border-top-left-radius: var(--border-radius-default);
      border: none;
      background: var(--table-background);

      > :not(:first-child) {
        border-left: 0.5px solid var(--border-darker-color);
      }

      > :first-child {
        border-top-left-radius: var(--border-radius-default);
      }

      > :last-child {
        border-top-right-radius: var(--border-radius-default);
      }

      th {
        padding: var(--spacing-12);
        height: 2px;
        text-align: left;

        > button {
          padding: 0;
          font-weight: 600;
          border: none;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          background: transparent;

          svg {
            color: var(--color-disabled-icon);
          }
        }

        > div {
          font-weight: 600;
        }

        &:hover {
          cursor: pointer;
          background: var(--content-background-hover);
        }
      }
    }
  }

  tbody {
    tr {
      border-top: 0.5px solid var(--border-darker-color) !important;

      td {
        padding: var(--spacing-12);
      }

      td:not(:first-child) {
        border-left: 0.5px solid var(--border-darker-color);
      }
    }

    > tr {
      td:first-child {
        text-align: center;
      }

      > :not(:first-child) {
        background: var(--layout-background);
      }
    }

    > :last-child > :first-child {
      border-bottom-left-radius: var(--border-radius-default);
    }

    > :last-child > :last-child {
      border-bottom-right-radius: var(--border-radius-default);
    }
  }

  td, th {
    padding: 0;
  }

  td:first-child, th:first-child {
    border-left: none;
  }

}


.paginationWrapper {
  background: var(--content-background);
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 40px;

  :last-child {
    margin-bottom: 4px;
  }
}

.sort {
  margin-left: var(--spacing-8);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2);
}

.sortActive {
  color: var(--color-active-icon) !important;
}

.loadingOverlay tbody {
  position: relative;
}

.loadingOverlay tbody:after {
  z-index: var(--z-index-table-overflow);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--content-background);
  opacity: 50%;
  background-position: top;
  background-repeat: no-repeat;
  content: "";
}

.loader {
  top: 45%;
  left: 50%;
  transform: translateX(50%);
  z-index: calc(var(--z-index-table-overflow) + 1);
  height: 160px;
  width: 160px;
  position: fixed;
}
