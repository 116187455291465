.wrapper {
  height: calc(100vh - var(--header-height));
  width: 100%;
  overflow-y: auto;

  :global {
    .ant-tabs-nav-wrap {
      background: var(--layout-background);
      padding-left: var(--spacing-32);
    }

    .ant-tabs {
      color: var(--color-text-default);
      min-height: fit-content;
      max-height: 100%;
    }

    .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content .ant-tabs-content-top {
      height: 100%;
    }

    .ant-tabs-content-holder {
      height: 100%;
      background: var(--content-background);
      padding: var(--spacing-16) var(--spacing-32) var(--spacing-24);
    }
  }
}

.header {
  position: relative;
  height: fit-content;
  background: var(--layout-background);

  div {
    background: var(--layout-background);
  }
}

.actions {
  position: absolute;
  top: var(--spacing-24);
  right: var(--spacing-24);
}
