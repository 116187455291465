.wrapper {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-8);

  :global {
    .ant-input {
      border-color: var(--color-disabled-icon);
    }
  }

  input {
    font-size: var(--font-size-13);
    padding: var(--spacing-4);
    height: fit-content;
  }
}

.isSelected {


  :global {
    .ant-input {
      border-color: var(--color-active-icon);
    }
  }
}
