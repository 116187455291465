.wrapper {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;


  li {
    font-size: var(--font-size-13);
    text-align: center;
    background: var(--pagination-background);
    margin-right: 0;
    border-radius: 0;
    border: none;

    border-right: 1px solid var(--border-color);

    a {
      color: var(--color-text-default);
    }
  }

  li:hover {
    background: var(--dropdown-element-hover);
    border-color: var(--border-color);
  }

  :global {
    .ant-pagination {
      border-radius: var(--border-radius-default);
      border: 1px solid var(--border-color) !important;
    }

    .ant-pagination-item-active a {
      color: var(--color-active-text)
    }

    .ant-pagination-prev {
      display: none;
    }

    .ant-pagination-next {
      display: none;
    }
  }
}
