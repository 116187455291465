
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;

  font-style: normal;
  font-weight: #{var(--font-weight-normal)};
  line-height: 1.5rem;
  font-family: '.SF NS Mono', 'Arial', sans-serif;
  letter-spacing: 0.02em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-rendering: optimizeLegibility;
}

.light-theme {
  --main-bg: #{var(--default-bg-gradient)};
  --section-background: #{var(--color-simple-white-darker)};
  --header-background: #{var(--color-simple-light-header-background)};
  --sidebar-background: #{var(--color-simple-white)};
  --layout-background: #{var(--color-simple-white)};
  --content-background: #{var(--color-simple-white-darker)};
  --content-background-hover: #{var(--color-simple-white-hovered)};
  --pagination-background: #{var(--color-simple-white-darker)};
  --table-background: #{var(--color-simple-white-darker)};
  --modal-background: #{var(--color-simple-white)};
  --dropdown-background: #{var(--color-simple-white)};
  --tag-background: #{var(--color-simple-white)};
  --border-darker-color: #{var(--color-darker-lines)};
  --border-color: #{var(--color-simple-light-border)};
  --sidebar-delimiter-color: #{var(--color-simple-lines)};
  --delimiter-color: #E2E4F2;
  --color-text-default: #{var(--color-simple-dark)};
  --color-text-orange: #{var(--color-simple-warning)};
  --color-light-disabled-text: #{var(--color-simple-light-gray)};
  --color-light-text: #{var(--color-simple-white)};
  --color-active-text: #{var(--color-simple-main)};
  --color-active-text-hover: #{var(--color-simple-main-hovered)};
  --color-disabled-text: #{var(--color-simple-gray)};
  --color-default: #{var(--color-simple-dark)};
  --color-disabled-icon: #{var(--color-simple-light-gray)};
  --color-disabled-icon-hover: #{var(--color-simple-gray)};
  --color-active-icon: #{var(--color-simple-main)};
  --color-active-icon-hover: #{var(--color-simple-main-hovered)};
  --color-active-icon-active: #{var(--color-simple-main-active)};
  --color-logo: #{var(--color-simple-dark)};
  --dropdown-element-hover: #{var(--color-simple-white-hovered)};
  --color-filter-checkbox: #{var(--color-simple-dark-pale-border)};
  --color-svg-icon-header: #{var(--color-simple-main)};
  --skeleton-color: #{var(--color-simple-light-gray)};
  --border-image-color: #{var(--color-simple-white)};

  --color-error: #{var(--color-simple-red)};
  --color-success: #{var(--color-simple-green)};

  --tag-close-icon: #{var(--color-simple-gray)};
  --tag-close-icon-hover: #{var(--color-simple-light-gray)};
  --tag-background-hover: #{var(--color-simple-white-hovered)};
  --tag-background-active: #{var(--color-simple-white-active)};
  --view-all-tags-button-hover: #{var(--color-simple-light-blue)};
  --color-active-text-active: #{var(--color-simple-main-active)};
  --color-critical-amount: #{var(--color-simple-warning)};
  --color-link-background: #{var(--color-simple-light-blue)};
  --color-button-header: #{var(--color-simple-light-blue)};
  --color-button-filled: #{var(--color-simple-main)};
  --color-button-filled-hovered: #{var(--color-simple-main-hovered)};
  --color-button-filled-active: #{var(--color-simple-main-active)};
  --color-button-filled-text: #{var(--color-simple-white)};

  --color-icon-purple: #{var(--color-simple-purple)};
  --color-icon-green: #{var(--color-simple-green)};

  --color-checked-row: #{var(--color-simle-cheked-row)};
  --color-viewed-row: #{var(--color-simle-viewed-row)};
  --color-reported-row: #{var(--color-simle-reported-row)};

  --color-non-active-link: #{var(--color-simple-white)};
}

.dark-theme {
  --main-bg: #{var(--color-simple-dark)};
  --section-background: #{var(--color-simple-dark)};
  --header-background: #{var(--color-simple-black)};
  --sidebar-background: #{var(--color-simple-dark)};
  --layout-background: #{var(--color-simple-light-dark)};
  --content-background: #{var(--color-simple-light-dark)};
  --content-background-hover: #{var(--color-simple-dark)};
  --pagination-background: #{var(--color-simple-dark)};
  --table-background: #{var(--color-simple-dark)};
  --modal-background: #{var(--color-simple-lighter-dark)};
  --dropdown-background: #{var(--color-simple-lighter-dark)};
  --tag-background: #{var(--color-simple-dark)};
  --border-darker-color: #{var(--color-simple-black)};
  --border-color: #{var(--color-simple-black)};
  --sidebar-delimiter-color: #{var(--color-simple-lines-dark)};
  --delimiter-color: #{var(--color-simple-lines-dark)};
  --color-text-default: #{var(--color-simple-white)};
  --color-light-disabled-text: #{var(--color-simple-gray-opacity)};
  --color-active-text: #{var(--color-simple-main)};
  --color-active-text-hover: #{var(--color-simple-main)};
  --color-disabled-text: #{var(--color-simple-gray-opacity)};
  --color-default: #{var(--color-simple-white)};
  --color-disabled-icon: #AFB1BA;
  --color-active-icon: #{var(--color-simple-main)};
  --color-logo: #{var(--color-simple-white)};
  --dropdown-element-hover: #{var(--color-simple-lighter-dark-hovered)};
  --color-filter-checkbox: #{var(--color-simple-dark-pale-border)};
  --color-svg-icon-header: #{var(--color-simple-black)};

  --color-error: #{var(--color-simple-red)};
  --color-success: #{var(--color-simple-green)};

  --color-critical-amount: #{var(--color-simple-warning)};

  --color-button-header: #{var(--color-simple-main)};
  --color-button-filled: #{var(--color-simple-main)};
  --color-button-filled-text: #{var(--color-simple-white)};
  --color-button-filled-hovered: #{var(--color-simple-main-hovered)};

  --color-icon-purple: #{var(--color-simple-purple)};
  --color-icon-green: #{var(--color-simple-green)};

  --color-checked-row: #{var(--color-simle-cheked-row)};
  --color-viewed-row: #{var(--color-simle-viewed-row)};
  --color-reported-row: #{var(--color-simle-reported-row)};

  --color-non-active-link: #{var(--color-simple-white)};
}

:root {
  // z-index
  --z-index-table-overflow: 2;

  // Spacing
  //---------------------------------
  --spacing-100: 100px;
  --spacing-64: 64px;
  --spacing-56: 56px;
  --spacing-40: 40px;
  --spacing-32: 32px;
  --spacing-24: 24px;
  --spacing-16: 16px;
  --spacing-14: 14px;
  --spacing-12: 12px;
  --spacing-8: 8px;
  --spacing-6: 6px;
  --spacing-4: 4px;
  --spacing-2: 2px;

  // Typography
  //---------------------------------
  --font-family--sfmono: ".SF NS Mono", "Arial", sans-serif;

  // Font size
  //---------------------------------
  --font-size-28: 28px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-15: 15px;
  --font-size-13: 13px;
  --font-size-12: 12px;
  --font-size-10: 10px;

  // Line height
  //---------------------------------
  --line-height-32: 32px;
  --line-height-28: 28px;
  --line-height-24: 24px;
  --line-height-20: 20px;
  --line-height-18: 18px;
  --line-height-16: 16px;

  // Font weight
  //----------------------------------
  --font-weight-normal: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;


  // Colors
  //----------------------------------
  --color-simple-black: #000000;
  --color-simple-dark: #0E0E1C;
  --color-simple-light-dark: #171731;
  --color-simple-lighter-dark: #1E1E38;
  --color-simple-lighter-dark-hovered: #1D285A;
  --color-simple-gray: #AFB1BA;
  --color-simple-light-gray: #D1D2D9;
  --color-simple-lines: #ECEEF3;
  --color-simple-lines-dark: rgba(255, 255, 255, 0.16);
  --color-darker-lines: #DADFEC;
  --color-simple-white-darker: #F9FAFD;
  --color-simple-light-blue: rgba(51, 108, 255, 0.12);

  --color-simple-white: #FFFFFF;
  --color-simple-white-hovered: #F3F6FF;
  --color-simple-white-active: #EBF0FF;

  --color-simple-main: #336CFF;
  --color-simple-main-hovered: #2E61E6;
  --color-simple-main-active: #2957CF;

  --color-simple-green: #00BE4C;
  --color-simple-green-hovered: #03A343;
  --color-simple-green-active: #009A3D;

  --color-simple-red: #E1446A;
  --color-simple-red-hovered: #CA3D5F;
  --color-simple-red-active: #B63756;

  --color-simple-purple: #9933FF;
  --color-simple-purple-hovered: #8A2EE6;
  --color-simple-purple-active: #7C29CF;

  --color-simple-warning: #EE8D18;

  --color-simple-gray-opacity: #FFFFFF4C;
  --color-simple-light-header-background: #EFF2FF;
  --color-simple-light-border: #F2F4F9;
  --color-simple-dark-pale-border: #AFB1BA66;

  --color-simle-cheked-row: rgba(51, 108, 255, 0.08);
  --color-simle-viewed-row: rgba(153, 51, 255, 0.08);
  --color-simle-reported-row: rgba(0, 190, 76, 0.08);


  //Gradients
  --default-bg-gradient: linear-gradient(95deg, #F1F7FF 0%, #EEF0FF 93.35%);
  // Shadows
  --default-box-shadow: 0 32px 64px rgba(12, 0, 48, 0.16);

  // Border radius
  //---------------------------------
  --border-radius-4: 4px;
  --border-radius-default: 6px;

  // Components sizes
  //---------------------------------
  --dummy-height: 40px;
  --header-height: 66px;
  --sidebar-width: 244px;
  --sidebar-collapse-height: 80px;
  //Button
  --button-height-small: 32px;
  --button-height: 40px;
  --button-height-large: 48px;
  //Input
  --input-height-small: #{var(--dummy-height)};
  --input-height: 40px;
  --input-height-large: 48px;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
}
