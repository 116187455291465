.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
}

.filterHeader {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;

  svg {
    color: var(--color-disabled-text)
  }
}
.filterHeader:hover {
  color: var(--color-active-text);

  svg {
    color: var(--color-active-icon);
  }
}

.rotate {
  transform: rotateZ(180deg);
}
