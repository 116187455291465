.overlay {

}

.contentWrapper {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrapper:hover {
  color: var(--color-active-icon-active) !important;

  div {
    color: var(--color-active-icon-hover) !important;
  }

  button {
    color: var(--color-active-icon-hover) !important;
  }

  a {
    color: var(--color-active-icon-hover) !important;
  }
}

.dropdownOverlayWrapper {
  position: absolute;
  z-index: 1;
  color: var(--color-active-icon);
  cursor: pointer;
  padding: 0;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;

  button {
    color: var(--color-text-default)
  }
}

.dropdownOverlayWrapper:hover {
  color: var(--color-active-text-hover) !important;
}

.dropdownOverlayWrapper:active {
  color: var(--color-active-text-active) !important;
}

.active {
  color: var(--color-active-text-hover);

  span {
    color: var(--color-active-text-hover);
  }

  div {
    color: var(--color-active-icon-hover) !important;
  }

  button {
    color: var(--color-active-icon-hover) !important;
  }

  a {
    color: var(--color-active-icon-hover) !important;
  }
}
