.wrapper {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-12);
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;

  :global .ant-checkbox-inner {
    background-color: transparent;
    border-color: var(--color-filter-checkbox)
  }

  label {
    span {
      font-size: var(--font-size-13);
      color: var(--color-text-default);
    }
  }
}



.wrapper:hover {
  span {
    color: var(--color-active-text-hover)
  }
}

.isSelected {
  label {
    span {
      font-weight: var(--font-weight-600);
      color: var(--color-active-text)
    }
  }

  :global .ant-checkbox-inner {
    background-color: var(--color-active-text-hover);
    border-color: var(--color-filter-checkbox)
  }
}
