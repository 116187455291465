.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-13);
}

.dropdownContent {
  position: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-top: var(--spacing-4);

  button {
    cursor: pointer;
    color: var(--color-active-text);
    background: transparent;
    border: none;
  }

  svg {
    height: 6px;
    color: var(--color-disabled-icon);
  }
}

.overlay {
  width: 75px;
}

.activeOption {
  color: var(--color-active-text);
}

.arrowActive {
  svg {
    color: var(--color-active-text);
    margin-top: var(--spacing-8);
    transform: translateY(-50%) rotate(180deg);
  }
}
