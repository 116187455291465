
.wrapper {
  cursor: default;
  background: var(--sidebar-background);

  :global {
    .ant-layout-sider-trigger {
      cursor: default;
      height: var(--sidebar-collapse-height);
      top: var(--header-height);
      padding: var(--spacing-24) var(--spacing-24) var(--spacing-32);
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid var(--border-color) !important;
      background: var(--sidebar-background);
      color: var(--color-text-default);
    }

    .ant-layout-sider-children {
      overflow-y: auto;
      border-right: 1px solid var(--border-color) !important;
      background: var(--sidebar-background);
      margin-top: var(--sidebar-collapse-height);
    }
  }
}

.sidebarTriggerWrapper {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  min-width: fit-content;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  color: var(--color-text-default);
  padding: 0;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-12);
    height: 24px;
  }
}

.listIcon {
  height: 16px;
  width: 16px;
}

.sidebarTriggerWrapper:hover {
  color: var(--color-active-text);

  svg {
    color: var(--color-active-icon);
  }
}
