.tableWrapper {
  tbody {
    > tr {

      > :nth-last-child(1), > :nth-last-child(2) {
        > button {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }

      > td:nth-child(2) {
        width: 32px;
        padding: 0 var(--spacing-4);
      }

      > td:nth-child(3) {
        width: 32px;
        padding: 0 var(--spacing-8);
      }

      > td:first-child {
        width: fit-content;
        padding: var(--spacing-12) var(--spacing-4);
      }
    }
  }
}
