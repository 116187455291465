.wrapper {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-14);
  font-size: var(--font-size-13);

  a {
    color: var(--color-active-text);
  }
  a:hover {
    color: var(--color-active-text-hover);
  }
  a:active {
    color: var(--color-active-text-active);
  }
}

.currentLocation {
  color: var(--color-light-disabled-text);
}

.divider {
  color: var(--color-light-disabled-text);
}

.breadcrumbWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-14);
}
