
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  gap: var(--spacing-8);

  svg {
    color: var(--color-active-icon);
  }
}

.wrapper:hover {
  svg {
    color: var(--color-active-icon-hover);
  }
}

.link {
  span {
    color: var(--color-active-text);
  }

  &:hover {
    span {
      color: var(--color-active-text-hover);
    }
  }
}
