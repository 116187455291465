.wrapper {
  height: 438px;
  font-size: var(--font-size-15);

  * :not(button) {
    background: transparent !important;
  }

  button {
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--color-active-text);
    font-weight: var(--font-weight-600);
  }

  button:hover {
    color: var(--color-active-text-hover);
  }

  td > :first-child {
    font-size: var(--font-size-18);
  }

  td > div {
    background: transparent !important;
  }
}

.actionButton {
  display: inline;
  width: 280px;
  margin-top: var(--spacing-32);
}


.content {
  font-size: var(--font-size-13);
  margin-top: var(--spacing-24);
}

.header {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-18);
  margin-top: var(--spacing-32);
  color: var(--color-text-default)
}
