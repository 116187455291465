.wrapper {
  background: var(--header-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-24);
  font-size: var(--font-size-15);

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.rightGroup {
  display: flex;
  align-items: center;

  button {
    padding: 0 0 var(--spacing-2) 0;
    margin-top: var(--spacing-2);
    margin-left: var(--spacing-24);
    color: var(--color-text-default);
  }

  :not(:last-child) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 var(--spacing-24);
    border-right: 1px solid var(--delimiter-color);
  }
}
