.modal {
  background: var(--modal-background);
  padding: 0;

  :global {
    .ant-scrolling-effect {
      width: 100% !important;
    }

    .ant-modal {
      border-radius: var(--border-radius-default) !important;
      box-shadow: 0 2px 6px -20px rgba(0, 0, 0, 0.02), 0px 4px 12px -20px rgba(0, 0, 0, 0.02);
    }

    .ant-modal-footer {
      display: none
    }

    .ant-modal-content {
      border-radius: var(--border-radius-default) !important;
    }

    .ant-modal-body {
      border-radius: var(--border-radius-default) !important;
      background: var(--modal-background);
      padding: var(--spacing-40);
    }
  }
}

.modal > div {

}
