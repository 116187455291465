.wrapper {
  cursor: pointer;
  font-size: var(--font-size-13);
  height: 15px;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0;
  border: none;

 :global {
   .ant-picker {
     padding: 0 !important;
      display: flex;
     .ant-picker-range-separator {
       display: none;
     }

     .ant-picker-active-bar {
       display: none;
     }

     input {
       font-size: var(--font-size-12);
     }
   }
 }
}

.wrapper:hover {
  color: var(--color-active-text-hover);
}


.isSelected {
  font-weight: var(--font-weight-600);
  color: var(--color-active-text);
}

.inputsWrapper {
  background: var(--layout-background);
  border-radius: var(--border-radius-default);
  box-shadow: 0 0 0 1px var(--border-darker-color);
}
