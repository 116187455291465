.number {
  width: 32px;
}

.tableWrapper > div {
  max-height: 68vh;
  overflow-y: auto;

  tbody {
    tr {
      > :not(:first-child) {
        padding: var(--spacing-12);
      }

      > :nth-last-child(1), > :nth-last-child(2) {
        > button {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }
}

.company {
  width: 44%;
}

.date {
  width: 18%;
}

.amount {
  width: 19%;
}
