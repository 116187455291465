@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: '.SF NS Mono';

  font-display: swap;
  src: url('../fonts/sf-mono-medium.woff2') format('woff2');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: '.SF NS Mono';

  font-display: swap;
  src: url('../fonts/sf-mono-semibold.woff2') format('woff2');
}
@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: '.SF NS Mono';

  font-display: swap;
  src: url('../fonts/sf-mono-bold.woff2') format('woff2');
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: '.SF NS Mono';

  font-display: swap;
  src: url('../fonts/sf-mono-bold.woff2') format('woff2');
}
