.dropdownButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  div {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);

    svg {
      color: var(--color-disabled-text);
    }
  }
}

.active {
  div {
    color: var(--color-active-text);

    svg {
      margin-top: var(--spacing-8);
      transform: translateY(-50%) rotate(180deg);
      color: var(--color-active-icon);
    }
  }
}

.overlay {
  ul {
    width: 310px;

    li {
      span {
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            color: var(--color-active-icon);
          }
        }
      }
    }
  }
}
