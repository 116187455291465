.wrapper {
  :global {
    .ant-tooltip-inner {
      background-color: var(--content-background);
      border-radius: var(--border-radius-4);
      font-size: var(--font-size-12);
      color: var(--color-default);}
    .ant-tooltip-arrow {
      span {
        --antd-arrow-background-color: linear-gradient(to right bottom, var(--content-background), var(--content-background));
      }
    }
  }
}
