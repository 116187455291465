
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  cursor: pointer;
  color: var(--color-active-text)
}

.wrapper:hover {
  color: var(--color-active-text-hover)
}
