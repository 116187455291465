.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tgEnabled {
  >:last-child {
    width: 0;
    visibility: hidden;
  }
}

.tgDisabled {
  >:first-child {
    display: none;
  }
}
