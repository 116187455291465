.dropdownButton {
  width: 105px;
  cursor: pointer;
  background: var(--layout-background);
  padding: var(--spacing-6) var(--spacing-12);
  border-radius: var(--border-radius-default);
  border: none;
  height: 32px;
  box-shadow: 0 2px 6px -20px rgba(0, 0, 0, 0.02), 0 4px 12px -20px rgba(0, 0, 0, 0.02);

  svg {
    color: var(--color-disabled-icon);
  }

  div {
    display: flex;
    gap: var(--spacing-8);
    align-items: center;
  }
}


.dropdownButtonActive {
  background: var(--tag-background-hover);

  svg {
    margin-top: 6px;
    transform: translateY(-50%) rotate(180deg);
    color: var(--color-active-text-hover);
  }

  div {
    color: var(--color-active-text-hover);
  }
}

.overlay {
  ul {
    background: var(--color-simple-white-darker);
    width: fit-content;

    padding: var(--spacing-12) 0;

    li {
      height: fit-content;
      border-bottom: none !important;
      padding: 0;
      display: flex;
      align-items: center;
      margin: 0 var(--spacing-12);

      &:not(:last-of-type) {
        margin-bottom: var(--spacing-12);
      }

      &:hover {
        background: var(--color-simple-white-darker);
      }


      > span {
        box-shadow: 0 2px 6px -20px rgba(0, 0, 0, 0.08), 0px 4px 12px -20px rgba(0, 0, 0, 0.02);

        > span {
          width: fit-content;
          height: 32px;
          display: flex !important;
          align-items: center;
          gap: var(--spacing-) !important;

          span {
            display: flex;
            align-items: center;
          }

          &:hover {
            background: var(--color-simple-white) !important;
          }

          :global {
            .ant-tag-close-icon {
              margin-left: 0;
              position: inherit;
              top: initial;
              right: initial;
            }
          }
        }
      }
    }
  }
}
